import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer__container">
        <h3>Designed and Developed with ❤️ by</h3>
        <a href="#">Akash Shrivastava</a>
      </div>
    </footer>
  );
};

export default Footer;
