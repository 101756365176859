import React from 'react';

const CTA = () => {
  return (
    <div className="cta">
      <a
        href="https://drive.google.com/file/d/1YclpDxodhn_UAgtBOIenBtXUtUo2Q9Oq/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
        className="btn"
      >
        View Resume
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
};

export default CTA;
